import Helpers from './helpers'

export default class API {
    static getStrapiURL(path = '') {
        let base = process.env.NEXT_PUBLIC_CLIENT_STRAPI_API + '/api/'

        if (typeof window === 'undefined') {
            base = process.env.NEXT_PUBLIC_SERVER_STRAPI_API + '/api/'
        }

        return `${base}${path}`
    }

    static async fetchAPI(path, query = null) {
        const requestUrl = API.getStrapiURL(path)
        let defaultQuery = '?populate=*'
        let queryString
        query ? (queryString = defaultQuery + query) : (queryString = defaultQuery)
        const response = await fetch(requestUrl + queryString)
        const data = await response.json()
        return data
    }

    static async getSiteOptions() {
        const data = await this.fetchAPI('site-option', null)
        return data.data ? data.data.attributes : null
    }

    static async getSingleType(contentType) {
        const data = await this.fetchAPI(contentType)
        //console.log(data)
        return data.data.attributes ? data.data.attributes : null
    }

    static async getProjects() {
        const requestUrl = API.getStrapiURL('works')

        let queryUrl = requestUrl + '?populate=*,work_category,thumbnail'

        const response = await fetch(queryUrl)
        const projects = await response.json()

        return projects.data.map(project => project.attributes)
    }

    static async getWork(filters) {
        const requestUrl = API.getStrapiURL('workarchive')

        let queryUrl = requestUrl + '?populate=*,work_category'

        filters ? (queryUrl = queryUrl + '&category=[' + filters + ']') : ''

        const response = await fetch(queryUrl)
        const data = await response.json()

        return data ? data : null
    }

    static async getColletionType(contentType, query = null) {
        const data = await this.fetchAPI(contentType)

        return data.data ? data.data : null
    }

    static async getSingleBySlug(contentType, slug) {
        const data = await this.fetchAPI(contentType + '/' + slug)

        return data.data ? data.data.attributes : null
    }

    static async getOtherWorks(currentArticle) {
        const data = await this.fetchAPI('works', '&pagination[page]=1&pagination[pageSize]=12&filters[slug][$ne]=' + currentArticle)

        const shuffled = [...data.data].sort(() => 0.5 - Math.random())

        const randomWorks = shuffled.slice(0, 3)

        return randomWorks ? randomWorks : null
    }

    static getImage(image, size) {
        const imageUrl = Helpers.getImage(image, size)

        return imageUrl
    }

    static getNextImage(image, size) {
        const imageUrl = Helpers.getNextImage(image, size)

        return imageUrl
    }

    static getGalleryImage(image, size) {
        const imageUrl = Helpers.getGalleryImage(image, size)

        return imageUrl
    }

    static newGetOriginalImage(url) {
        const newUrl = Helpers.getOriginalGalleryImage(url)

        return newUrl
    }

    static getNextOriginalImage(image) {
        const imageUrl = Helpers.getNextOriginalImage(image)

        return imageUrl
    }

    static getNextGalleryImage(image, size) {
        const imageUrl = Helpers.getNextGalleryImage(image, size)

        return imageUrl
    }

    static getFile(file) {
        const fileUrl = Helpers.getFile(file)

        return fileUrl
    }

    static redirect(ctx, to) {
        Helpers.redirect(ctx, to)
    }
}
