import React from 'react'
import parse from 'html-react-parser'

const Footer = props => {
    return (
        <footer className='relative z-0 px-2 py-2 bg-black 768:px-5 768:py-5'>
            <svg className='block w-full mb-36 1024:mb-60' viewBox='0 0 1040 159' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M953.438 0H845.344V159H953.438C993.013 159 1040 133.56 1040 79.4789C1040 25.3975 993.013 0 953.438 0ZM941.798 123.004H895.375V35.9956H941.798C963.64 35.9956 989.962 43.5466 989.962 79.6267C989.962 115.707 963.633 123.004 941.798 123.004Z'
                    fill='white'
                />
                <path
                    d='M762.566 107.452H694.632L672.234 159H623.31V150.203L690.165 0H767.307L833.945 150.203V159H784.879L762.566 107.452ZM746.621 71.4564L731.537 35.9956H725.696L710.471 71.4564H746.621Z'
                    fill='white'
                />
                <path
                    d='M330.438 107.452H262.505L240.114 159H191.189V150.203L258.045 0H335.18L401.817 150.203V159H352.724L330.438 107.452ZM314.494 71.4564L299.438 35.9956H293.604L278.385 71.4564H314.494Z'
                    fill='white'
                />
                <path d='M179.663 8.90918L86.8299 116.755H179.663V158.979H0V150.105L93.0512 42.2236H0V0H179.663V8.90918Z' fill='white' />
                <path d='M611.91 0V159H602.264L463.255 78.2614V159H413.231V0H422.877L561.886 80.7667V0H611.91Z' fill='white' />
            </svg>
            <div className='grid items-end grid-cols-3 gap-4 1140:grid-cols-4'>
                <div>{props.address_rich && <span className='uppercase text-12 768:text-16 768:!leading-5 text-white'>{parse(props.address_rich)}</span>}</div>
                <div>
                    <p className='uppercase text-12 768:text-16 !leading-5 text-white'>
                        {props.contactEmail ? (
                            <a href={'mailto:' + props.contactEmail} rel='noreferrer' target='_blank' className='transition-opacity hover:opacity-70'>
                                {props.contactEmail}
                            </a>
                        ) : (
                            <></>
                        )}

                        {props.phone ? (
                            <>
                                <br />
                                <a href={'tel:' + props.phone} rel='noreferrer' target='_blank' className='transition-opacity hover:opacity-70'>
                                    {props.phone}
                                </a>
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                </div>
                <div className='hidden 1140:block'>
                    <p className='uppercase text-12 768:text-16 !leading-5 text-white'>
                        If you don’t like reading,
                        <br />
                        sign up to our newsletter.
                    </p>
                    <div id='mc_embed_signup'>
                        <form
                            action='https://zanad.us11.list-manage.com/subscribe/post?u=c1250450329292db0440fdb92&amp;id=cade3f59af&amp;f_id=006c96e0f0'
                            method='post'
                            id='mc-embedded-subscribe-form'
                            name='mc-embedded-subscribe-form'
                            className='validate'
                            target='_blank'
                            noValidate>
                            <div id='mc_embed_signup_scroll' className='max-w-[240px] relative overflow-hidden'>
                                <div className='mc-field-group'>
                                    <label htmlFor='mce-EMAIL' className='sr-only'>
                                        Email Address<span className='asterisk'>*</span>
                                    </label>
                                    <input
                                        className='block w-full py-1 text-white uppercase bg-transparent border-b required email border- focus:outline-0'
                                        type='email'
                                        placeholder='E-MAIL'
                                        defaultValue=''
                                        name='EMAIL'
                                        id='mce-EMAIL'
                                    />
                                </div>
                                <div id='mce-responses' className='clear foot'>
                                    <div className='response' id='mce-error-response' style={{ display: 'none' }}></div>
                                    <div className='response' id='mce-success-response' style={{ display: 'none' }}></div>
                                </div>
                                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
                                    <input type='text' name='b_c1250450329292db0440fdb92_cade3f59af' tabIndex='-1' defaultValue='' />
                                </div>
                                <div className='optionalParent'>
                                    <div className='clear foot'>
                                        <input
                                            className='absolute top-0 right-0 cursor-pointer button w-7 h-7'
                                            type='submit'
                                            defaultValue='Subscribe'
                                            name='subscribe'
                                            id='mc-embedded-subscribe'
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <p className='uppercase text-12 768:text-16 !leading-5 text-white'>
                        {props.socialMedia ? (
                            props.socialMedia.map((profile, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <a key={i} href={profile.url} target='_blank' rel='noreferrer' className='transition-opacity hover:opacity-70'>
                                            {profile.social_network}
                                        </a>
                                        <br />
                                    </React.Fragment>
                                )
                            })
                        ) : (
                            <></>
                        )}
                    </p>
                </div>
            </div>
            <span className='text-12 768:text-16 !leading-5 tracking-wider text-white uppercase absolute -rotate-90 bottom-16 -right-12 pt-2 pb-1 768:bottom-24 768:-right-16 768:pt-2 768:pb-1'>
                <span className='opacity-50'>made by </span>
                <a href='https://v-a.studio/' target='_blank' rel='noreferrer' className='transition-opacity opacity-50 hover:opacity-100'>
                    V–A Studio
                </a>
            </span>
        </footer>
    )
}

export default Footer
