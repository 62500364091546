import Head from 'next/head'

const MetaTags = ({ title, description, image }) => {
      return (
            <Head>
                  <meta charSet='UTF-8' />
                  <meta name='viewport' content='width=device-width, initial-scale=1' />
                  <title>{title != '' ? `ZANAD | ${title}` : `ZANAD | An Independent Animation Studio`}</title>
                  <meta name='description' content={description} />
                  <meta property='og:title' content={title != '' ? `${title} | ZANAD` : `ZANAD | An Independent Animation Studio`} />
                  <meta property='og:description' content={description} />
                  <meta property='og:image' content={image} />
                  <meta property='og:image:alt' content='' />
                  <meta property='og:locale' content='en' />
                  <meta property='og:type' content='website' />
                  <meta name='twitter:card' content={image} />
                  <meta name='theme-color' content='#FFFFFF' />
                  <link rel='icon' type='image/png' sizes='32x32' href='/assets/favicon-32x32.png' media='(prefers-color-scheme: light)' />
                  <link rel='icon' type='image/png' sizes='16x16' href='/assets/favicon-16x16.png' media='(prefers-color-scheme: light)' />
                  <link rel='icon' type='image/png' sizes='32x32' href='/assets/favicon-32x32-dark.png' media='(prefers-color-scheme: dark)' />
                  <link rel='icon' type='image/png' sizes='16x16' href='/assets/favicon-16x16-dark.png' media='(prefers-color-scheme: dark)' />
                  <link rel='apple-touch-icon' sizes='180x180' href='/assets/apple-touch-icon.png' />
                  <link rel='manifest' href='/assets/site.webmanifest' />
                  <link rel='mask-icon' href='/assets/safari-pinned-tab.svg' color='#000000' />
                  <meta name='msapplication-TileColor' content='#FFFFFF' />
                  <meta name='theme-color' content='#FFFFFF'></meta>
            </Head>
      )
}

export default MetaTags
