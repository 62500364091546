import Link from 'next/link'

const ButtonTicker = props => {
    return (
        <Link scroll={false} href={props.url}>
            <a
                className='btn-ticker whitespace-nowrap inline-block rounded-[100%] bg-black border-black border-[3px] fluid-type-small ml-4 text-white uppercase overflow-hidden'
                target={props.external ? '_blank' : ''}>
                <span className='btn-ticker-inner'>
                    <span data-text={props.title}>{props.title}</span>
                </span>
            </a>
        </Link>
    )
}

export default ButtonTicker
