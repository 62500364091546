import { useEffect, useState } from "react";
import App from "next/app";
import API from "strapi/api";
import { MenuContextProvider } from "contexts/MenuContext";
import { motion, AnimatePresence } from "framer-motion";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import Screensaver from "components/Screensaver";
import CookieBanner from "components/Elements/CookieBanner";
import Custom404 from "./404";
import Router from "next/router";
import Script from "next/script";

Router.events.on("routeChangeStart", () => {
  document.body.classList.add("loading");
});

Router.events.on("routeChangeComplete", () => {
  setTimeout(() => {
    document.body.classList.remove("loading");
  }, 1500);
});

Router.events.on("routeChangeError", () => {
  setTimeout(() => {
    document.body.classList.remove("loading");
  }, 1500);
});

import "styles/global.scss";

function MyApp({ Component, pageProps, router }) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(null);

  const handleVideoPlaying = (bool) => setIsVideoPlaying(bool);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, 350);

    setIsVideoPlaying(false);
  }, [router.asPath]);

  return (
    <MenuContextProvider>
      <>
        <Navigation
          socialMedia={
            pageProps.general ? pageProps.general.social_profile : null
          }
          email={pageProps.general ? pageProps.general.email : null}
        />
        <AnimatePresence exitBeforeEnter initial="false">
          <motion.div
            key={router.asPath}
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
                transition: {
                  duration: 0.35,
                  delay: 1.3,
                },
              },
              exit: {
                opacity: 0,
                transition: {
                  duration: 0.35,
                },
              },
            }}
          >
            {pageProps.statusCode ? (
              <Custom404 />
            ) : (
              <Component
                handleVideoPlaying={handleVideoPlaying}
                {...pageProps}
              />
            )}

            <Footer
              phone={pageProps.general ? pageProps.general.phone_number : null}
              socialMedia={
                pageProps.general ? pageProps.general.social_profile : null
              }
              contactEmail={pageProps.general ? pageProps.general.email : null}
              address_rich={
                pageProps.general ? pageProps.general.address_rich : null
              }
            />

            <Script
              strategy="afterInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-X6PWJ5CV60"
            ></Script>
            <Script id="ga-analytics">
              {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-X6PWJ5CV60');
                        `}
            </Script>

            <Screensaver isVideoPlaying={isVideoPlaying} />
          </motion.div>
        </AnimatePresence>
        <CookieBanner />
      </>
    </MenuContextProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  let [appProps, general] = await Promise.all([
    App.getInitialProps(appContext),
    API.getSiteOptions(),
  ]);

  appProps.pageProps.general = general;

  return { ...appProps };
};

export default MyApp;
