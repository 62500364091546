import React, { useEffect, useRef } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import ButtonTicker from "components/Elements/ButtonTicker";

const CookieBanner = () => {
  const cookieConsentRef = React.useRef();
  const handleAcceptButtonClick = () => {
    cookieConsentRef?.current?.accept?.();
  };

  return (
    <CookieConsent
      ref={cookieConsentRef}
      buttonStyle={{ display: "none" }}
      disableStyles={true}
    >
      <div className="cookie-banner-inner 640:flex 640:flex-row 640:items-center w-full 768:w-max">
        <p className="uppercase text-13 768:text-16 leading-4 mb-4 640:mb-0 text-center 640:text-left">
          This website uses cookies to enhance the user experience.
          {/* <span className='hidden 640:block'></span>
                    Click on the button to consent the use of cookies. */}
        </p>
        <button
          onClick={handleAcceptButtonClick}
          className="btn-ticker-small whitespace-nowrap block 640:inline-block rounded-[100%] bg-black border-black border-[3px] fluid-type-extrasmall 640:ml-4 mx-auto text-white uppercase overflow-hidden"
        >
          <span className="btn-ticker-inner">
            <span data-text="Accept" className="speed-25">
              Accept
            </span>
          </span>
        </button>
      </div>
    </CookieConsent>
  );
};
export default CookieBanner;
