import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

// function numberInRange(min, max) {
//     let randomNumber =  Math.floor(Math.random() * (max - min + 1)) + min;
//     if (randomNumber > 0 && randomNumber < 100) {
//         randomNumber = 100;
//     }
//     if (randomNumber < 0 && randomNumber > -100) {
//         randomNumber = -100;
//     }
//     return randomNumber;
// }

const AnimatedBox = props => {
      const [isFirstLoad, setIsFirstLoad] = useState(true)
      const control = useAnimation()
      //const [translateElement, setTranslateElement] = useState(80)
      const [ref, inView] = useInView({
            threshold: 0,
            triggerOnce: false,
      })

      useEffect(() => {
            setTimeout(() => {
                  setIsFirstLoad(false)
            }, 2000)
      }, [])

      useEffect(() => {
            if (inView) {
                  control.start('visible')
            } else {
                  control.start('hidden')
            }
            // setTranslateElement(numberInRange(-250, 250))
      }, [control, inView])

      return (
            <motion.span
                  className='animated-box'
                  ref={ref}
                  variants={{
                        visible: {
                              opacity: 1,
                              //translateX: 0,
                              transition: {
                                    duration: 0.125,
                                    delay: isFirstLoad ? props.num * 0.18 + 1.25 : props.num * 0.18,
                                    //ease: [0.34, 1.56, 0.64, 1],
                              },
                        },
                        hidden: {
                              opacity: 0,
                              //translateX: 0,
                              //translateX: translateElement
                        },
                  }}
                  onAnimationComplete={ref => {
                        // remove stroke-dasharray after the animation completes
                        // needs to be on the next paint since its still animating the stroke
                        requestAnimationFrame(ref => {
                              //console.log(ref.current)
                        })
                  }}
                  initial='hidden'
                  animate={control}>
                  {props.children}
            </motion.span>
      )
}

export default AnimatedBox
