import MetaTags from 'components/MetaTags'
import { ContainerWithOffset } from 'components/Elements/Container'
import AnimatedBox from 'components/Elements/AnimatedBox'
import ButtonTicker from 'components/Elements/ButtonTicker'

export default function Custom404() {
    return (
        <>
            <MetaTags title='Page Not Found' description='' image='' />
            <main>
                <h1 className='offscreen-title'>Page not found - Error 404</h1>
                <ContainerWithOffset>
                    <p className='mb-24 uppercase fluid-type'>
                        <AnimatedBox num={1}>
                            <span className='flex flex-row justify-between'>
                                <span>Probably</span>
                                <span>late</span>
                            </span>
                        </AnimatedBox>
                        <AnimatedBox num={3}>
                            <span className='flex flex-row justify-end'>
                                <span>
                                    for something <span className='glyph-icon'>r</span>
                                </span>
                            </span>
                        </AnimatedBox>
                        <AnimatedBox num={2}>
                            <span className='flex flex-row justify-between'>
                                <span className='inline-block'>Page not found</span>
                                <span className='inline-block'>
                                    <ButtonTicker title='Homepage' url='/' external={false} />
                                </span>
                            </span>
                        </AnimatedBox>
                    </p>
                </ContainerWithOffset>
            </main>
        </>
    )
}
