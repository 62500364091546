export default class Helpers {
    static getImage(image, size) {
        const foundImage =
            image.data && image.data.attributes.formats && image['data']['attributes']['formats'][size]
                ? process.env.NEXT_PUBLIC_CLIENT_STRAPI_API + image['data']['attributes']['formats'][size]['url']
                : this.getOriginalImage(image.data.attributes.url)

        return foundImage
    }

    static getNextImage(image, size) {
        const foundImage =
            image.data && image.data && image.data.attributes.formats && image['data']['attributes']['formats'][size]
                ? process.env.NEXT_PUBLIC_SERVER_STRAPI_API + image['data']['attributes']['formats'][size]['url']
                : (image.data ? this.getNextOriginalImage(image.data.attributes.url):"")

        return foundImage
    }

    static getGalleryImage(image, size) {
        const foundImage =
            image.attributes && image['attributes']['formats'][size]
                ? process.env.NEXT_PUBLIC_CLIENT_STRAPI_API + image['attributes']['formats'][size]['url']
                : this.getOriginalImage(image.attributes.url)

        return foundImage
    }

    static getOriginalGalleryImage(image) {
        const foundImage =
            image.attributes && image['attributes']
                ? process.env.NEXT_PUBLIC_CLIENT_STRAPI_API + image['attributes']['url']
                : this.getOriginalImage(image.attributes.url)

        return foundImage
    }

    static getNextGalleryImage(image, size) {
        const foundImage =
            image.attributes && image['attributes']['formats'][size]
                ? process.env.NEXT_PUBLIC_SERVER_STRAPI_API + image['attributes']['formats'][size]['url']
                : this.getNextOriginalImage(image.attributes.url)

        return foundImage
    }

    static getOriginalImage(image_url) {
        return process.env.NEXT_PUBLIC_CLIENT_STRAPI_API + image_url
    }

    static getNextOriginalImage(image_url) {
        return process.env.NEXT_PUBLIC_SERVER_STRAPI_API + image_url
    }

    static getServerinfo() {
        return process.env.NEXT_PUBLIC_CLIENT_STRAPI_API
    }

    static redirect(ctx, to) {
        if (!ctx) {
            window.location.replace(to)
        } else if (!ctx.req) {
            window.location.replace(to)
            ctx.res.end()
        } else {
            ctx.res.writeHead(302, { Location: to })
            ctx.res.end()
        }
    }
}
