import { useRouter } from 'next/router'
import { createContext, useState, useEffect } from 'react'

export const MenuContext = createContext()

export const MenuContextProvider = ({ children }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const router = useRouter()

    useEffect(() => {
        setIsMenuOpened(false)
    }, [router.route])

    return <MenuContext.Provider value={{ isMenuOpened, setIsMenuOpened }}>{children}</MenuContext.Provider>
}
