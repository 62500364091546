import { useContext } from 'react'
import { MenuContext } from 'contexts/MenuContext'

const Hamburger = () => {
    const { isMenuOpened, setIsMenuOpened } = useContext(MenuContext)

    return (
        <>
            <button
                onClick={() => setIsMenuOpened(!isMenuOpened)}
                className='group pointer-events-auto overflow-hidden fixed z-50 top-[7px] right-2 768:top-[18px] 768:right-5 bg-white 768:p-5 w-14 h-8 768:w-20 768:h-11 border-2 border-black rounded-[100%]'>
                <span className='relative inline-block  768:-left-1.5  768:transition-transform  768:translate-x-0  768:group-hover:-translate-x-full'>
                    <div className={`nav-icon ${isMenuOpened ? 'opened' : 'closed'}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </span>
                <span className='hidden 768:block nav-title bg-white absolute top-0 -left-1 py-4 px-0 uppercase text-[1.72rem] leading-[.48] transition-transform translate-x-full h-11 group-hover:translate-x-0 '>
                    Menu
                </span>
            </button>
        </>
    )
}

export default Hamburger
