export const Container = ({ children }) => {
    return <div className='w-full px-2 mx-auto 768:px-5'>{children}</div>
}

export const ContainerWithOffset = ({ children }) => {
    return <div className='w-full px-2 pt-12 mx-auto 768:px-5 768:pt-56'>{children}</div>
}

export const ContainerHomepage = ({ children }) => {
    return <div className='w-full mx-auto px-2 768:px-5 pt-20 768:pt-[50vh] 2048:pt-[34rem]'>{children}</div>
}