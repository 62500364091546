import { useRef } from "react";
import { useIdleTimer } from "react-idle-timer";

const Screensaver = ({ isVideoPlaying }) => {
  // if (typeof window !== "undefined") {
  // const screensaverElement = document.getElementById('screensaver');
  // }
  let i = 0;
  let interval;
  let currentSticker;
  let stickersList = Array.from(Array(28).keys());
  const screensaverElement = useRef();

  const selectedSticker = (stickersList) =>
    stickersList.splice((Math.random() * stickersList.length) | 0, 1);

  const addSticker = () => {
    if (stickersList.length === 0) {
      stickersList = Array.from(Array(24).keys());
    }

    currentSticker = selectedSticker(stickersList);
    const sticker = document.createElement("img");
    sticker.src = `/assets/stickers/sticker-${currentSticker}.svg`;
    sticker.alt = "";
    sticker.style.top = `${Math.random() * 100}%`;
    sticker.style.left = `${Math.random() * 100}%`;
    sticker.classList.add("screensaver-sticker");
    sticker.classList.add("sticker-" + currentSticker);
    sticker.style.transform = `rotate(${Math.round(
      Math.random() * 360
    )}deg) translateX(-150px)`;
    screensaverElement && screensaverElement.current.appendChild(sticker);
    i++;
  };

  const onIdle = () => {
    screensaverElement.current.style.display = "block";
    i = 0;
    if (!isVideoPlaying) interval = setInterval(addSticker, 1000);
  };

  const onActive = () => {
    screensaverElement.current.style.display = "none";
    clearInterval(interval);
    while (screensaverElement.current.firstChild) {
      screensaverElement.current.firstChild.remove();
    }
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: 4000,
    events: [
      "click",
      "scroll",
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  return (
    <div
      ref={screensaverElement}
      id="screensaver"
      className="fixed top-0 left-0 z-50 hidden w-full h-full"
    />
  );
};
export default Screensaver;
