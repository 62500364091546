import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import { MenuContext } from "contexts/MenuContext";
import { motion } from "framer-motion";
import Link from "next/link";
import Hamburger from "components/Navigation/Hamburger";

const Navigation = (props) => {
  const { isMenuOpened, setIsMenuOpened } = useContext(MenuContext);
  const [scroll, setScroll] = useState(1);
  const [windowWidth, setWindowWidth] = useState(null);
  const router = useRouter();

  const glyphPreloader = () => {
    const div = document.getElementById("glyph-preload");

    // All glyps = b c l q r s t z 0 1 2 3 4 5 6 7 8 9
    // Removed: Eye = 6, Bomb explosion = 5, Two eyes = r
    const glyphs = [
      "b",
      "c",
      "l",
      "q",
      "s",
      "t",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "7",
      "8",
      "9",
    ];

    const RANDOM_NUMBER_IN_GLYPHS_ARRAY = Math.floor(
      Math.random() * glyphs.length
    );
    let i = RANDOM_NUMBER_IN_GLYPHS_ARRAY;
    let count = 0;

    const interval = setInterval(() => {
      div.innerHTML = glyphs[i];
      i = (i + 1) % glyphs.length;
      count++;
      if (count >= 4) {
        clearInterval(interval);
      }
    }, 250);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      setIsMenuOpened(0);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    document.addEventListener(
      "scroll",
      () => {
        const scrollCheck = window.scrollY < 100;
        if (scrollCheck !== scroll) {
          setScroll(scrollCheck);
        }
      },
      { passive: true }
    );
    document.addEventListener("keydown", handleKeyPress, { passive: true });
  }, []);

  useEffect(() => {
    glyphPreloader();
  }, [router.route]);

  return (
    <>
      <header
        key={windowWidth}
        className="fixed z-40 w-full px-2 pt-2 pointer-events-none 768:px-5 768:pt-5 mix-blend-difference"
      >
        <Link scroll={false} href="/">
          <a
            onClick={() => setIsMenuOpened(0)}
            className={`relative aspect-[13/2] pointer-events-auto w-max z-40 logo flex transition-height ease-easeInOutQuint duration-500
                            ${
                              scroll
                                ? "h-[30px] 768:h-[90px] 991:h-[120px] 1280:h-[160px] 1920:h-[220px]"
                                : "!h-[30px] 768:!h-[40px] 1920:!h-[60px]"
                            } 
                            ${
                              router.route !== "/"
                                ? "!h-[30px] 768:!h-[40px]"
                                : ""
                            }
                            ${
                              isMenuOpened && router.route === "/"
                                ? "!h-[30px] 768:!h-[40px]"
                                : ""
                            }`}
          >
            <svg
              className="h-full text-white"
              viewBox="0 0 1040 159"
              fill="none"
            >
              <path
                d="M953.438 0H845.344V159H953.438C993.013 159 1040 133.56 1040 79.4789C1040 25.3975 993.013 0 953.438 0ZM941.798 123.004H895.375V35.9956H941.798C963.64 35.9956 989.962 43.5466 989.962 79.6267C989.962 115.707 963.633 123.004 941.798 123.004Z"
                fill="currentColor"
              />
              <path
                d="M762.566 107.452H694.632L672.234 159H623.31V150.203L690.165 0H767.307L833.945 150.203V159H784.879L762.566 107.452ZM746.621 71.4564L731.537 35.9956H725.696L710.471 71.4564H746.621Z"
                fill="currentColor"
              />
              <path
                d="M330.438 107.452H262.505L240.114 159H191.189V150.203L258.045 0H335.18L401.817 150.203V159H352.724L330.438 107.452ZM314.494 71.4564L299.438 35.9956H293.604L278.385 71.4564H314.494Z"
                fill="currentColor"
              />
              <path
                d="M179.663 8.90918L86.8299 116.755H179.663V158.979H0V150.105L93.0512 42.2236H0V0H179.663V8.90918Z"
                fill="currentColor"
              />
              <path
                d="M611.91 0V159H602.264L463.255 78.2614V159H413.231V0H422.877L561.886 80.7667V0H611.91Z"
                fill="currentColor"
              />
            </svg>
            <motion.div
              key={router.route}
              initial="visible"
              animate="hidden"
              variants={{
                visible: {
                  opacity: 1,
                  position: "block",
                },
                hidden: {
                  opacity: 0,
                  position: "absolute",
                  transition: {
                    duration: 0,
                    delay: 1.25,
                  },
                },
              }}
            >
              <span
                id="glyph-preload"
                className="glyph-icon text-[45px] 768:text-[55px] 1920:text-[80px] leading-[.75] ml-2 block text-white"
              ></span>
            </motion.div>
          </a>
        </Link>
      </header>
      <Hamburger />
      <div
        className={`close-overlay fixed z-30 w-screen h-screen top-0 left-0 bg-white/60 transition-opacity duration-500 ${
          isMenuOpened
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      ></div>
      <div
        style={{ transform: "translateX(100%)" }}
        className={`fixed pointer-events-auto z-40 w-full 991:w-1/2  h-screen right-0 top-0 bg-black transition-transform ease-easeInOutQuint duration-500 ${
          isMenuOpened ? "!translate-x-0" : "!translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-between h-full px-4 py-4 768:px-5">
          <nav>
            <ul className="main-menu">
              <li>
                <Link scroll={false} href="/work">
                  <a
                    // onClick={() => setIsMenuOpened(!isMenuOpened)}
                    className="inline-block text-white uppercase fluid-type animated-border light-border"
                  >
                    Work
                  </a>
                </Link>
              </li>
              <li>
                <Link scroll={false} href="/about">
                  <a
                    // onClick={() => setIsMenuOpened(!isMenuOpened)}
                    className="inline-block text-white uppercase fluid-type animated-border light-border"
                  >
                    About
                  </a>
                </Link>
              </li>
              <li>
                <Link scroll={false} href="/unreleased">
                  <a
                    // onClick={() => setIsMenuOpened(!isMenuOpened)}
                    className="inline-block text-white uppercase fluid-type animated-border light-border"
                  >
                    Unreleased
                  </a>
                </Link>
              </li>
              <li>
                <Link scroll={false} href="/careers">
                  <a
                    // onClick={() => setIsMenuOpened(!isMenuOpened)}
                    className="inline-block text-white uppercase fluid-type animated-border light-border"
                  >
                    Careers
                  </a>
                </Link>
              </li>
              <li>
                <Link scroll={false} href="/contact">
                  <a
                    // onClick={() => setIsMenuOpened(!isMenuOpened)}
                    className="inline-block text-white uppercase fluid-type animated-border light-border"
                  >
                    Contact
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
          <div className="640:grid 640:grid-cols-2 640:gap-0 640:items-end">
            <ul>
              {props.socialMedia ? (
                props.socialMedia.map((profile, i) => {
                  return (
                    <li key={i}>
                      <a
                        href={profile.url}
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                        className="text-lg leading-none text-white uppercase transition-opacity 768:text-2xl hover:opacity-70"
                      >
                        {profile.social_network}
                      </a>
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
            <div className="pt-2 640:pt-0 640:text-right">
              {props.email ? (
                <a
                  href={"mailto:" + props.email}
                  className="text-lg leading-none text-white uppercase transition-opacity 768:text-2xl hover:opacity-70"
                >
                  {props.email}
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
